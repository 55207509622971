<template>
  <v-card flat class="position-relative">
    <block v-if="loading"></block>
    <!--div class="d-flex flex-wrap align-self-center mb-3">
            <v-bottom-sheet v-model="showFilter" inset>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn  v-bind="attrs" v-on="on" class="filter-btn btnclean">FILTRAR
                    </v-btn>
                </template>
                <v-sheet class="text-center">
                    <v-btn class="mt-6" text color="error" @click="showFilter = !showFilter">
                        cerrar
                    </v-btn>
                    <v-btn class="mt-6" text color="primary" @click="showFilter = !showFilter">Filtrar</v-btn>
                    <div class="my-3 pa-4">
                        <div>
                            <date-picker label="
                            "
                                @dateSelected="(param) => (filterProducts.start = param)"></date-picker>
                            <date-picker label="hasta"
                                @dateSelected="(param) => (filterProducts.end = param)"></date-picker>
                        </div>

                    </div>

                </v-sheet>
            </v-bottom-sheet>

        </div-->
    <GeneralFilter
      class="border-boxGlobal backgroundGlobal"
      :search="search"
      :filterEndpoint="$API.orders.getProvidersProductsOrdersFiltered"
      @filtered="filterHandler"
      @emptyFilter="getOrderList"
    >
      <DxDataGrid class="isScrolledByGeneralFilter" :data-source="ordersList" @exporting="onExporting" :paging="{ enabled: false }" style="max-height: 600px">
        <DxExport :enabled="true" />
        <DxColumn
          :width="80"
          cell-template="show-template"
          caption=""
        ></DxColumn>
        <DxColumn data-field="OrderStatus" caption="Estado"></DxColumn>
        <DxColumn data-field="ID_PurchaseOrder" caption="ID"></DxColumn>
        <DxColumn
          data-field="ID_PurchaseLegalInvoiceNumber"
          caption="Número de factura"
        ></DxColumn>
        <DxColumn
          data-field="PurchaseOrderDate"
          caption="Fecha de factura"
        ></DxColumn>
        <DxColumn data-field="PurchaseOrderTotal" caption="Precio"></DxColumn>
        <DxColumn
          data-field="PurchaseInvoiceDate"
          caption="Fecha de orden"
        ></DxColumn>
        <DxColumn cell-template="show-extrabutton" caption=""></DxColumn>
        <template #show-template="{ data }">
          <a
            href="#"
            class="mx-1"
            @click.prevent.stop="
              showOrder = data;
              showModal = true;
            "
          >
            <v-icon color="primary">mdi-eye</v-icon>
          </a>
        </template>

        <template #show-extrabutton="{ data }">
          <v-btn
            v-if="
              parseFloat(data.data.PurchaseOrderTotalRet) > 0 &&
              (data.data.PurchaseOrderType == 'CCF' ||
                data.data.PurchaseOrderType == 'FCF' || data.data.PurchaseOrderType == 'FAF')
            "
            class="btnsave"
            @click="generateWithholdingReceipt(data.data)"
          >
            Generar comprobante<br />
            de retencion
          </v-btn>
        </template>
      </DxDataGrid>
    </GeneralFilter>

    <!-- Show  Modal -->
    <v-dialog v-model="showModal" scrollable :max-width="$vuetify.breakpoint.smAndDown ? '90%':'75%'">
      <OrderDetails
        :order="showOrder"
        v-if="showModal"
        @close="closeDialog"
      ></OrderDetails>
    </v-dialog>

    <alerts
      style="z-index: 20000 !important"
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    >
    </alerts>
  </v-card>
</template>

<script>
import OrderDetails from "./../PurchaseOrderDetails";
import { DxDataGrid, DxColumn, DxExport } from "devextreme-vue/data-grid";
import GeneralFilter from "../../../components/GeneralFilter";
//import DatePicker from "../../../components/DatePicker";
import { printToExcel } from "@/helpers/printToexcel";
import Alerts from "@/components/Alerts";
import Block from "@/components/Block";

export default {
  name: "AddOrderHistory",
  props: ["data"],
  components: {
    DxDataGrid,
    DxColumn,
    OrderDetails,
    DxExport,
    Block,
    Alerts,
    GeneralFilter,
  },
  data() {
    return {
      form: {},
      showFilter: false,
      showModal: false,
      ordersList: [],
      filterProducts: {
        pending: true,
        provider: this.data.ID_Provider,
        start: null,
        end: null,
        business: JSON.parse(localStorage.getItem("user")).businessid,
        branch: JSON.parse(localStorage.getItem("user")).branch,
      },
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      showDetailsMessages: false,
      loading: false,
      isFiltered: false,
      search: {
        filter: null,
      },
    };
  },
  watch: {
    showModal() {
      if (!this.showModal) {
        this.closeDialog();
      }
    },
    filterProducts: {
      deep: true,
      handler: "getOrderList",
    },
  },

  mounted() {
    this.getOrderList();
  },
  methods: {
    filterHandler(response) {
      this.ordersList = response;
    },
    clearFilter() {
      this.isFiltered = false;
    },
    closeAlert() {
      this.loading = false;
      this.alert.show = false;

      if (this.alert.options == "redirectTab") {
        this.$store.commit("providersData/changeView", "tab-5");
        this.$store.dispatch("reloadData", "purchase");
      }
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "showErrorDetails") {
        this.showDetailsMessages = true;
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    onExporting(e) {
      printToExcel(e, "Historial de compras");
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },
    getOrderList() {
      let filterObj = { ...this.filterProducts };
      Object.keys(filterObj).map((key) => {
        if (filterObj[key] == "All") delete filterObj[key];
      });
      this.$API.orders
        .getProvidersProductsHistory(filterObj)
        .then((response) => {
          this.ordersList = response;
          console.log(this.ordersList)
        });
    },
    closeDialog() {
      this.showOrder = false;
      this.showModal = false;
      this.getOrderList();
    },
    generateWithholdingReceipt(data) {
      this.loading = true;
      this.data.codpointsale = localStorage.getItem("codPointSale");
      this.$API.orders
        .generateWithholdingReceipt({
          orderHeader: data,
          provider: this.data,
          user: JSON.parse(localStorage.getItem("user")).id,
        })
        .then((response) => {
          if (response.estado == "PROCESADO") {
            let mensaje = "";
            mensaje = "Se ha realizdo y procesado la factura.<br/><br/>";

            mensaje += "Estado: " + response.estado + "<br/>";
            mensaje +=
              "Mensaje del DTE: <br/> - " + response.descripcionMsg + "<br/>";
            mensaje += "Observaciones DTE: <br/>";
            if (response.observaciones.length == 0) {
              mensaje += "- Ninguna observación <br/>";
            } else {
              for (let i = 0; i < response.observaciones.length; i++) {
                mensaje += "- " + response.observaciones[i] + "<br/>";
              }
            }

            //this.datatoprint.header = await this.$API.invoices.getInvoice(response.billingDteJsonHeader.ID_Invoice);
            //this.datatoprint.body = await this.$API.invoices.getAllInvoiceItems(response.billingDteJsonHeader.ID_Invoice);
            this.showAlert(
              "successHtml",
              "Factura generada",
              mensaje,
              "redirectTab"
            );
          } else {
            let mensaje =
              "Se ha rechazado la factura, tomar en cuenta las observaciones del Ministerio de Hacienda y realizarla de nuevo.<br/><br/>";
            mensaje += "Estado: " + response.estado + "<br/>";
            mensaje +=
              "Mensaje del DTE: <br/> - " + response.descripcionMsg + "<br/>";
            mensaje += "Observaciones DTE: <br/>";
            if (response.observaciones.length == 0) {
              mensaje += "- Ninguna observación <br/>";
            } else {
              for (let i = 0; i < response.observaciones.length; i++) {
                mensaje += "- " + response.observaciones[i] + "<br/>";
              }
            }

            this.showAlert("successHtml", "Factura generada", mensaje);
          }
        })
        .catch(() => {
          this.loading = false;
          let message =
            "Ocurrio un error crítico al realizar la factura. ¡Por favor contáctarse con el administrador del sistema!";
          // message += "<br/><br/>"+JSON.stringify(err)
          //this.currenterrordata = JSON.stringify(err)
          this.showAlert("danger", "Advertencia", message);
        });
    },
  },
};
</script>

<style scoped>
.close-icon {
  color: red;
}

.filter-btn {
  position: absolute;
  top: 15px;
  left: 0px;
  z-index: 9;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"white pa-4",staticStyle:{"border-radius":"15px"}},[_c('v-btn',{staticClass:"btnclean mb-1",on:{"click":function($event){_vm.showfilterdata = !_vm.showfilterdata}}},[_vm._v(" Filtrar ")]),_c('GeneralFilter',{attrs:{"entity":"lista","filterEndpoint":_vm.$API.products.getProductsProviderFiltered,"search":_vm.search},on:{"emptyFilter":_vm.getProductsByProv,"filtered":_vm.filterHandler}},[_c('DxDataGrid',{staticClass:"isScrolledByGeneralFilter",staticStyle:{"max-height":"600px"},attrs:{"data-source":_vm.products,"key-expr":"ID_Provider","show-row-lines":_vm.showRowLines,"paging":{ enabled: false }},on:{"exporting":_vm.onExporting},scopedSlots:_vm._u([{key:"show-barcodes",fn:function({ data }){return [_c('v-btn',{staticClass:"btn-add",on:{"click":function($event){return _vm.showCodesOfProduct(data.data)}}},[_vm._v(" Ver códigos alternos ")])]}},{key:"show-template",fn:function({ data }){return [_c('a',{staticClass:"mx-1",attrs:{"href":"#"},on:{"click":function($event){return _vm.$store.dispatch('addWindow', {
              name: 'ManageProduct',
              component: 'ManageProduct',
              unique: true,
              meta: { product: data.data },
            })}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye")])],1)]}}])},[_c('DxScrolling',{attrs:{"row-rendering-mode":"virtual"}}),_c('DxExport',{attrs:{"enabled":true}}),(_vm.$store.getters['rolesUser/getCustomRole'](129))?_c('DxColumn',{attrs:{"width":80,"cell-template":"show-template","caption":""}}):_vm._e(),_c('DxColumn',{attrs:{"data-field":"ID_Item","caption":"ID"}}),_c('DxColumn',{attrs:{"cell-template":"show-barcodes","caption":"Códigos"}}),_c('DxColumn',{attrs:{"data-field":"ItemName","caption":"Nombre"}}),_c('DxColumn',{attrs:{"data-field":"ItemType","caption":"Tipo"}}),_c('DxColumn',{attrs:{"data-field":"ItemPrice","caption":"Precio en $"}}),_c('DxColumn',{attrs:{"data-field":"stock","caption":"Disponible"}})],1)],1),(_vm.$store.getters['rolesUser/getCustomRole'](130))?_c('DxButton',{attrs:{"width":300,"text":"Agregar productos a proveedor","type":"default","styling-mode":"contained","elementAttr":{ style: 'background-color: #00A178 !important;' }},on:{"click":function($event){return _vm.$store.dispatch('addWindow', {
        name: 'addProduct',
        component: 'addProduct',
        unique: true,
        meta: { product: _vm.providerID },
      })}}}):_vm._e(),_c('v-dialog',{attrs:{"scrollable":"","max-width":_vm.$vuetify.breakpoint.smAndDown ?'90%':'60%'},model:{value:(_vm.showfilterdata),callback:function ($$v) {_vm.showfilterdata=$$v},expression:"showfilterdata"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Filtros")])]),_c('v-card-text',[_c('v-select',{attrs:{"outlined":"","dense":"","attach":"","label":"Almacen","item-text":"name","item-value":"value","items":_vm.filterParams.warehouse},model:{value:(_vm.filters_selected.warehouse),callback:function ($$v) {_vm.$set(_vm.filters_selected, "warehouse", $$v)},expression:"filters_selected.warehouse"}}),_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":"Familia","items":_vm.families,"item-text":"name","attach":"","item-value":"id"},model:{value:(_vm.filters_selected.family),callback:function ($$v) {_vm.$set(_vm.filters_selected, "family", $$v)},expression:"filters_selected.family"}}),_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":"Categoría","items":_vm.filteredCategories,"item-text":"name","attach":"","item-value":"id","disabled":!_vm.filters_selected.family},model:{value:(_vm.filters_selected.category),callback:function ($$v) {_vm.$set(_vm.filters_selected, "category", $$v)},expression:"filters_selected.category"}}),_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":"Subcategoría","items":_vm.filteredSubcategories,"attach":"","item-text":"name","item-value":"id","disabled":!_vm.filters_selected.category},model:{value:(_vm.filters_selected.subcategory),callback:function ($$v) {_vm.$set(_vm.filters_selected, "subcategory", $$v)},expression:"filters_selected.subcategory"}}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-btn',{staticClass:"btnclean",on:{"click":function($event){_vm.showfilterdata = !_vm.showfilterdata;
              _vm.cleanFilters();}}},[_vm._v("Limpiar filtros")]),_c('v-btn',{staticClass:"btn-add",on:{"click":function($event){_vm.showfilterdata = !_vm.showfilterdata;
              _vm.filterValues();}}},[_vm._v("Aplicar")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.showBarCodes),callback:function ($$v) {_vm.showBarCodes=$$v},expression:"showBarCodes"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Códigos de barra / Códigos alternos ")]),_c('v-card-text',[_c('DxDataGrid',{attrs:{"data-source":_vm.codes},scopedSlots:_vm._u([{key:"check-principal",fn:function({data}){return [_c('div',[_c('center',[_c('v-checkbox',{attrs:{"readonly":""},model:{value:(data.data.PrincipalBarCode),callback:function ($$v) {_vm.$set(data.data, "PrincipalBarCode", $$v)},expression:"data.data.PrincipalBarCode"}})],1)],1)]}}])},[_c('DxColumn',{attrs:{"data-field":"BarCode","caption":"Códigos de barra / código alterno"}}),_c('DxColumn',{attrs:{"width":70,"cell-template":"check-principal","caption":"Principal"}})],1)],1),_c('v-card-actions',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"btncontinue",on:{"click":function($event){_vm.showBarCodes = false}}},[_vm._v(" Continuar ")])],1)],1)],1)],1),(_vm.alert.show)?_c('alerts',{attrs:{"properties":_vm.alert},on:{"close_alert":_vm.closeAlert,"accept_alert":_vm.acceptAlert}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
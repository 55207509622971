<template >
    <!--div ref="expansionPanelsContainer"-->
    <v-expansion-panel >
        <v-expansion-panel-header v-if="provider" :key="showReload" class="mt-2">
            PROVEEDOR : {{provider.ProviderName}}
            <template v-slot:actions>
                <v-btn class="mr-5" @click.native.stop="reload()" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="
                    $store.commit('providersData/setOrderDetails', null);
                    $store.commit('providersData/setOrder', null);
                    $store.dispatch('deleteWindowByUuid',{uuid : win.uuid})" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-tabs
                    v-model="tab"
                    dark background-color="primary"
                    icons-and-text
            >
                <v-tabs-slider color="red"></v-tabs-slider>

                <v-tab href="#tab-1" @click="changeTab('tab-1')"
                 v-if="$store.getters['rolesUser/getCustomRole'](96)"
                >
                    GESTIÓN DE PROVEEDOR
                    <v-icon>mdi-account-box</v-icon>
                </v-tab>

                <v-tab href="#tab-2" @click="changeTab('tab-2')"
                v-if="$store.getters['rolesUser/getCustomRole'](97)"
                
                >
                    GENERAR ORDENES DE COMPRA
                    <v-icon>mdi-plus</v-icon>
                </v-tab>

                <v-tab href="#tab-3" @click="changeTab('tab-3')">
                    HISTORIAL DE ORDENES DE COMPRAS
                    <v-icon>mdi-history</v-icon>
                </v-tab>
                <v-tab 
                v-if="$store.getters['rolesUser/getCustomRole'](128)"
                href="#tab-4" @click="changeTab('tab-4')">
                    PRODUCTOS
                    <v-icon>mdi-store</v-icon>
                </v-tab>
                <v-tab 
                href="#tab-5" @click="changeTab('tab-5')"   v-if="$store.getters['rolesUser/getCustomRole'](120)">
                    HISTORIAL COMPROBANTES DE RETENCIÓN
                    <v-icon>mdi-clipboard-text-clock</v-icon>
                </v-tab>
                <v-tab 
                href="#tab-6" @click="changeTab('tab-6')" v-if="$store.getters['rolesUser/getCustomRole'](125)">
                    Cuentas por Pagar
                    <v-icon>mdi-account-cash-outline</v-icon>
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" touchless>
                <v-tab-item value="tab-1">
                    <v-card flat class="databox pa-1">
                        <v-card-text class="white">
                            <ManageProvider :key="reloadNumber" :data="provider" :win="win"></ManageProvider>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item value="tab-2">
                    <v-card flat >
                        <v-card-text :key="reloadNumber" class="pa-1" >
                            <AddPurchase :key="reloadAddPurchase" :data="provider" :win="win"></AddPurchase>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item value="tab-3">
                    <v-card flat sclass="databox">
                        <v-card-text :key="reloadNumber" class="pa-1">
                            <AddOrderHistory :key="reloadAddPurchase" :data="provider"></AddOrderHistory>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item value="tab-4">
                    <v-card flat :key="producprov" >
                        <v-card-text :key="reloadAddPurchase" class="pa-1">
                            <ProductProvider v-if="$store.getters['rolesUser/getCustomRole'](128)" :key="reloadNumber" :data="provider" :win="win"></ProductProvider>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item value="tab-5">
                    <v-card flat :key="producprov" >
                        <v-card-text :key="reloadAddPurchase" class="pa-1">
                            <ReceipWithholdingtHistory v-if="$store.getters['rolesUser/getCustomRole'](120)" :key="reloadNumber" :data="provider" :win="win"></ReceipWithholdingtHistory>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item value="tab-6">
                    <v-card flat :key="producprov"  >
                        <v-card-text :key="reloadAddPurchase" class="pa-1">
                            <AccountsPayableList  v-if="$store.getters['rolesUser/getCustomRole'](125)" :key="reloadNumber" :provider="provider" :win="win"></AccountsPayableList>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-expansion-panel-content>
    </v-expansion-panel>
<!--/div-->
</template>


<script>
    import {mapState} from 'vuex';
    import ManageProvider from "./tabs/ManageProvider";
    import AccountsPayableList from './tabs/AccountsPayableList.vue';
    import AddPurchase from "./tabs/AddPurchase";
    import AddOrderHistory from "./tabs/AddOrderHistory";
    import ReceipWithholdingtHistory from "./tabs/ReceiptWithholdingHistory";
import ProductProvider from './partial/ProductProvider.vue';

    export default {
        name: "ProviderData",
        props: ['win'],

        data() {
            return {
                provider: this.win.meta.provider,
                reloadNumber: 0,
                showReload: true,
                tab: null,
            }
        },
        mounted() {
           
        },
        computed: {
            ...mapState('providersData', {
                tabState: state => state.currentView,
                reloadAddPurchase: state => state.reloaders.reloadAddPurchase
                
            }),
            ...mapState('vetProviders', {
                producprov: state => state.vetProductoprov,
               
            }),
            ...mapState(
      "rolesUser",
      {
        rolesStatus: (state) => state.roles,
      })
        },
        components: {
    AddOrderHistory,
    AddPurchase,
    ManageProvider,
    ProductProvider,
    ReceipWithholdingtHistory,
    AccountsPayableList
},
        watch: {
            tabState() {
                this.tab = this.tabState
            },
        },
        methods: {
            reloadData(payload) {
                this.$emit('reloadData', payload)
            },
            reload(){
                this.$store.dispatch('rolesUser/getAllRoles')
                this.$store.commit('providersData/setOrderDetails', null);
                this.$store.commit('providersData/setOrder', null);
                this.$store.commit('providersData/setReloaders', 'reloadAddPurchase')
                this.reloadNumber++
                this.showReload = true
                setTimeout(() => {
                window.scrollTo(0, this.$store.getters.getScrollTop)
                //alert(this.$store.getters.getScrollTop)
                },300);
            },
            changeTab(tab){
                this.$store.commit('providersData/changeView', tab)
                setTimeout(() => {
                window.scrollTo(0, this.$store.getters.getScrollTop)
                //alert(this.$store.getters.getScrollTop)
                },300);
                /*const openPanel = document.querySelector('.v-expansion-panel--active')
                const openPanelRect = openPanel.getBoundingClientRect()
                const expansionPanelsContainer = this.$refs.expansionPanelsContainer
                alert(expansionPanelsContainer.scrollTop)
                const expansionPanelsContainerRect = expansionPanelsContainer.getBoundingClientRect()
                
                const scrollTop =  openPanelRect.top - (expansionPanelsContainerRect.top + expansionPanelsContainer.scrollTop)+45
                                    alert(scrollTop)
                                    window.scrollTo(0, scrollTop)*/

               /*                     const openPanel = document.querySelector('.v-expansion-panel--active')
                            if (openPanel) {
                                const openPanelRect = openPanel.getBoundingClientRect()
                                setTimeout(() => {
                                // Obtenemos la posición del contenedor v-expansion-panels
                                const expansionPanelsContainer = this.$refs.expansionPanelsContainer
                                if (expansionPanelsContainer) {
                                    const expansionPanelsContainerRect = expansionPanelsContainer.getBoundingClientRect()
                                    // Ajustamos el desplazamiento para que se muestre en la parte superior del panel recién abierto
                                    //alert("Top: " + openPanelRect.top + ", "+ expansionPanelsContainerRect.top + ", " + expansionPanelsContainer.scrollTop + " | Bottom: " + openPanelRect.bottom + ", "+ expansionPanelsContainerRect.bottom)
                                    //const scrollTop = openPanelRect.top - (expansionPanelsContainerRect.top - expansionPanelsContainer.scrollTop) + 200
                                    //window.scrollTo(0, 0)
                                    const scrollTop = openPanelRect.bottom - openPanelRect.top //- ( expansionPanelsContainer.scrollTop - expansionPanelsContainerRect.bottom ) -50
                                    
                                    window.scrollTo(0, scrollTop)
                                    alert(scrollTop + ' top ' + openPanelRect.top +' otro top ' + expansionPanelsContainerRect.top + ' otro más top '+expansionPanelsContainer.scrollTop)
                                    
                                    console.log(scrollTop + ' top ' + openPanelRect.top +' otro bottom ' + expansionPanelsContainerRect.bottom + ' otro más top '+expansionPanelsContainer.scrollHeight)
                                    //expansionPanelsContainer.scrollTo({ top: scrollTop, behavior: 'smooth' })
                                }
                            }, 1500);
                            }*/
            }

        }

    }
</script>

<style scoped>
.databox{
    padding: 16px;
    border-radius: 15px;

}


</style>
